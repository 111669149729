import React, { useEffect } from 'react'
import {Link, useLocation} from 'react-router-dom'
import Meta from '../components/Meta'
import HeroSecondary from '../components/HeroSecondary'
import Card from '../components/Card'
import { ButtonNoRef } from '../components/Button'
import SectionContactUs from '../components/SectionContactUs'

import paths from '../constants/paths'

import styles from '../styles/pages/Markets.module.css'
import buttonStyles from '../styles/components/Button.module.css'


const scroll = () => {
    if (typeof window !== 'undefined') {

        var id = 'pageTop';
        var yOffset;
        var element = document.getElementById(id);
        let y;
        if (window.scrollY === 0) {
            yOffset = -50;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }
        else {
            yOffset = -10;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }

        window.scrollTo({top: y, behavior: 'smooth'});
    }
}

const Markets = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Meta
                title='Ubitech | Markets'
            />

            <section className={styles.heroSection}>
                <HeroSecondary
                    title='Our Markets'
                    description='We provide cutting edge wireless communication applications and medical software solutions'
                >
                    <ButtonNoRef
                        onClick={() => {scroll()}}
                        buttonstyle={buttonStyles.buttonStyle1}
                        buttonsize={buttonStyles.buttonSizeMedium}
                        text='Learn More'
                    />
                </HeroSecondary>
            </section>

            <div className={styles.page} id='pageTop'>
                <div className={styles.pageContainer}>

                    <section className={styles.introSection}>
                        <div className={styles.introSectionContainer}>
                            <h1 className={styles.introSectionTitle}>
                                Explore Innovation
                            </h1>
                            <p className={styles.introSectionParagraph}>
                                We deliver fully tailored and customizable solutions based on our in-house innovative technologies to provide a 
                                cost effective and fast to market solution. For clients with their own development teams, we provide 
                                technical consultancy services and can provide an advisory role throughout the client project.
                            </p>
                        </div>
                    </section>

                    <section className={styles.cardSection}>
                        <div className={styles.cardSectionContainer}>
                            <div className={styles.card}>
                                <Card 
                                    image={{ backgroundImage: `url("/images/card_image_healthcare.jpg")` }}
                                    title='UbiTheraPlay Software Suite'
                                    description='The medical gaming platform for children with neurological limitations affecting motor functions'
                                >
                                    <a href='https://www.ubitheraplay.com/' target='_blank' rel='noopener noreferrer'>
                                        <ButtonNoRef
                                            buttonstyle={buttonStyles.buttonStyle2}
                                            buttonsize={buttonStyles.buttonSizeExtraSmall}
                                            text='See More'
                                        />
                                    </a>
                                </Card>
                            </div>

                            <div className={styles.card}>
                                <Card 
                                    image={{backgroundImage: 'url(/images/card_image_5g.jpg)'}}
                                    title='Wireless Communications'
                                    description='Our cutting edge wireless technologies'
                                >
                                    <Link to={paths.communications}>
                                        <ButtonNoRef 
                                            buttonstyle={buttonStyles.buttonStyle2}
                                            buttonsize={buttonStyles.buttonSizeExtraSmall}
                                            text='See More'
                                        />
                                    </Link>
                                </Card>
                            </div>
                        </div>
                    </section>
            
                    <section className={styles.contactSection}>
                        <div className={styles.seperationContainer}>
                            <div className={styles.seperation} />
                        </div>

                        <SectionContactUs>
                            <Link to={paths.contact}>
                                <ButtonNoRef 
                                    buttonstyle={buttonStyles.buttonStyle1}
                                    buttonsize={buttonStyles.buttonSizeMedium}
                                    text='Contact Us'
                                />
                            </Link>
                        </SectionContactUs>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Markets
