import React, { useEffect, useLayoutEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Meta from '../components/Meta'
import Hero from '../components/Hero'
import Section from '../components/Section'
import { ButtonNoRef } from '../components/Button'
import SectionContactUs from '../components/SectionContactUs'

import paths from '../constants/paths'

import section_overview from '../assets/images/section_overview.svg'
import section_communications from '../assets/images/section_communications.svg'
import section_software from '../assets/images/section_software.svg'

import styles from '../styles/pages/Home.module.css'
import buttonStyles from '../styles/components/Button.module.css'


const scroll = () => {
    if (typeof window !== 'undefined') {

        var id = 'pageTop';
        var yOffset;
        var element = document.getElementById(id);
        let y;
        if (window.scrollY === 0) {
            yOffset = -50;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }
        else {
            yOffset = -10;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }

        window.scrollTo({top: y, behavior: 'smooth'});
    }
}

const Home = () => {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Meta
                title='Ubitech | Home'
            />
        
            <Hero
                left={
                    <Link to={paths.contact}>
                        <ButtonNoRef
                            buttonstyle={buttonStyles.buttonStyle1}
                            buttonsize={buttonStyles.buttonSizeMedium}
                            text='Contact Us'
                        />
                    </Link>
                }
                right={
                    <ButtonNoRef
                        onClick={() => {scroll()}}
                        buttonstyle={buttonStyles.buttonStyle1}
                        buttonsize={buttonStyles.buttonSizeMedium}
                        text='Learn More'
                    />
                }   
            />

            <div className={styles.sectionContainer} id='pageTop'>
                <section className={styles.sectionOverview}>
                    <Section
                        imageOnRight={true}
                        image={section_overview}
                        alt='Image'
                        title='Overview'
                        description='Ubitech offers consultancy services in three areas, Wireless Communications, Specialist Game Development, and Software Distribution.'
                        extraContent='Our passion in our work consistantly leads to bespoke customer solutions based on our innovative technologies. We are tactically harmonising and exploiting innovations to provide benefits in the form of new technologies and emerging products.'
                        hasExtraContent={true}
                    >
                        <Link to={paths.about}>
                            <ButtonNoRef 
                                buttonstyle={buttonStyles.buttonStyle1}
                                buttonsize={buttonStyles.buttonSizeMedium}
                                text='More About Us'
                            />
                        </Link>
                    </Section>
                </section>

                <div className={styles.seperationContainer}>
                    <div className={styles.seperation} />
                </div>

                <section className={styles.sectionSoftware}>
                    <Section
                        imageOnRight={false}
                        image={section_software}
                        alt='Image'
                        title='Healthcare Software'
                        description='Ubitech is developing exer-games for the healthcare sector with UbiTheraPlay, the medical gaming platform for children with neurological disorders affecting motor functions.'
                    >
                        <a target='_blank' href='https://www.ubitheraplay.com/' rel='noopener noreferrer'>
                            <ButtonNoRef 
                                buttonstyle={buttonStyles.buttonStyle1}
                                buttonsize={buttonStyles.buttonSizeMedium}
                                text='UbiTheraPlay'
                            />
                        </a>
                    </Section>
                </section>

                <div className={styles.seperationContainer}>
                    <div className={styles.seperation} />
                </div>

                <section className={styles.sectionCommunications}>
                    <Section
                        imageOnRight={true}
                        image={section_communications}
                        alt='Image'
                        title='Wireless Communications'
                        description='Ubitech’s UbiNet technology enables ad-hoc wireless communications and distribution without reliance on network infrastructure.'
                    >
                        <Link to={paths.communications}>
                            <ButtonNoRef 
                                buttonstyle={buttonStyles.buttonStyle1}
                                buttonsize={buttonStyles.buttonSizeMedium}
                                text='Communications'
                            />
                        </Link>
                    </Section>
                </section>

                <div className={styles.seperationContainer}>
                    <div className={styles.seperation} />
                </div>

                <section className={styles.contactSection}>
                    <SectionContactUs>
                        <Link to={paths.contact}>
                            <ButtonNoRef 
                                buttonstyle={buttonStyles.buttonStyle1}
                                buttonsize={buttonStyles.buttonSizeMedium}
                                text='Contact Us'
                            />
                        </Link>
                    </SectionContactUs>
                </section>
            </div>
        </>
    )
}

export default Home;