import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import paths from '../constants/paths'

import styles from '../styles/components/Nav.module.css'

const Nav = () => {

    /* State Variables */
    const [hasScrolled, setHasScolled] = useState(false);
    const [navMobileMenuSelected, setNavMobileMenuSelected] = useState(false);

    /* Page Scroll Functions */
    const handleScroll = () => {
        if (!window.pageYOffset === 0) {
            setHasScolled(true);
        }
        else if (hasScrolled) {
            setHasScolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    /* Mobile Nav Functions */
    const handleNavMobileMenuSelected = () => {
        if (!navMobileMenuSelected) {
            openNavMobileMenu();
        }
        else {
            closeNavMobileMenu();
        }
    }

    const openNavMobileMenu = () => {
        // Ensure script is being run in web page in web browser
        if (typeof window !== 'undefined') {
            document.getElementById('menuIcon').innerHTML = '&#215;'; // Set to HTML X Icon
            document.body.style.overflow = 'hidden'; // Prevent scroll on main body
        }
        setNavMobileMenuSelected(true);
    }

    const closeNavMobileMenu = () => {
        // Ensure script is being run in web page in web browser
        if (typeof window !== 'undefined') {
            document.getElementById('menuIcon').innerHTML = '&#9776;'; // Set to HTML Hamburger Icon
            document.body.style.overflow = 'unset'; // Allow scroll on main body
            document.getElementById('mobileMenuDiv').scrollTop = 0; // Set mobile menu scroll to top of div for next time
        }
        setNavMobileMenuSelected(false);
    }

    return (
        <nav className={hasScrolled ? styles.navScroll : styles.navMain}>
            
            {/* Desktop Nav for 840px and above */}
            <div className={styles.navDesktop}>
                <h1 className={styles.title}>
                    <Link to={paths.index}>
                        <div className={hasScrolled ? styles.logoScroll : styles.logoMain} />
                    </Link>
                </h1>
                <ul className={styles.menuList}>
                    <li className={styles.menuLink}>
                        <Link to={paths.about}>About</Link>
                    </li>
                    <li className={styles.menuLink}>
                        <Link to={paths.markets}>Markets</Link>
                    </li>
                    <li className={styles.menuLink}>
                        <Link to={paths.contact}>Contact</Link>
                    </li>
                </ul>
            </div>

            {/* Mobile Nav for less than 840px */}
            <div className={styles.navMobile}>
                <div className={styles.mobileContainer}>
                    <h1 className={styles.mobileTitle}>
                        <Link to={paths.index} onClick={closeNavMobileMenu}>Ubitech</Link>
                    </h1>
                    <div className={styles.mobileMenu}>
                        <label id='menuIcon' className={navMobileMenuSelected ? styles.mobileMenuIconX : styles.mobileMenuIconBurger} htmlFor='toggle'>&#9776;</label>
                        <input className={styles.mobileMenuIconToggle} type='checkbox' id='toggle' onClick={handleNavMobileMenuSelected} />
                    </div>
                </div>
                <div id='mobileMenuDiv' className={navMobileMenuSelected ? styles.mobileMenuListContainer : styles.none}>
                    <ul className={styles.mobileMenuList}>
                        <li className={styles.mobileMenuLinkPrimary}>
                            <Link to={paths.markets} onClick={closeNavMobileMenu}>Markets</Link>
                        </li>
                        <li className={styles.mobileMenuLinkSecondary}>
                            <a target='_blank' href='https://ubitheraplay.com/' rel='noopener noreferrer' onClick={closeNavMobileMenu}>
                                UbiTheraPlay
                            </a>
                        </li>
                        <li className={styles.mobileMenuLinkSecondary}>
                            <Link to={paths.communications} onClick={closeNavMobileMenu}>Wireless Communications</Link>
                        </li>
                        <li className={styles.mobileMenuLinkPrimary}>
                            <Link to={paths.about} onClick={closeNavMobileMenu}>About</Link>
                        </li>
                        <li className={styles.mobileMenuLinkPrimary}>
                            <Link to={paths.contact} onClick={closeNavMobileMenu}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> 
    )
}

export default Nav
