import React, { useEffect } from 'react'
import Meta from '../components/Meta'
import HeroSecondary from '../components/HeroSecondary'
import ContactForm from '../components/ContactForm'
import { ButtonNoRef } from '../components/Button'

import styles from '../styles/pages/Contact.module.css'
import buttonStyles from '../styles/components/Button.module.css'
import { useLocation } from 'react-router-dom'

const scroll = () => {
    if (typeof window !== 'undefined') {

        var id = 'pageTop';
        var yOffset;
        var element = document.getElementById(id);
        let y;
        if (window.scrollY === 0) {
            yOffset = -50;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }
        else {
            yOffset = -10;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }

        window.scrollTo({top: y, behavior: 'smooth'});
    }
}

const Contact = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Meta
                title='Ubitech | Contact'
            />

            <section className={styles.heroSection}>
                <HeroSecondary
                    title='Contact Us'
                    description='Enquire about our innovative solutions and services today'
                >
                    <ButtonNoRef
                        onClick={() => {scroll()}}
                        buttonstyle={buttonStyles.buttonStyle1}
                        buttonsize={buttonStyles.buttonSizeMedium}
                        text='Contact Now'
                    />
                </HeroSecondary>
            </section>

            <div className={styles.contact} id='pageTop'>
                <div className={styles.contactContainer}>

                    <section className={styles.contactSection}>

                        <div className={styles.contactFormContainer}>
                            <ContactForm />
                        </div>

                        <div className={styles.detailsContainer}>
                            <div className={styles.emailContainer}>
                                <h1 className={styles.emailTitle}>
                                    Email
                                </h1>
                                <p className={styles.emailAddress}>
                                    info@ubitechit.com
                                </p>
                            </div>
                            <div className={styles.addressContainer}>
                                <h1 className={styles.addressTitle}>
                                    Address
                                </h1>
                                <div className={styles.addressHolder}>
                                    <address className={styles.address}>Penrhyn Road</address>
                                    <address className={styles.address}>Kingston upon Thames</address>
                                    <address className={styles.address}>Surrey</address>
                                    <address className={styles.address}>KT1 2EE</address>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default Contact
