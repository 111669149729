import React from 'react'
import { Link } from 'react-router-dom';

import paths from '../constants/paths'

import styles from '../styles/components/Footer.module.css'


const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>

                {/* Ubitech */}
                <div className={styles.ubitech}>
                    <Link to={paths.index}>Ubitech</Link>
                </div>

                {/* Nav Links */}
                <div className={styles.navTitleDiv}>
                    <h2 className={styles.navTitle}>Navigation</h2>
                </div>
                <div className={styles.navLinks1}>
                    <ul className={styles.list}>
                        <li className={styles.link}>
                            <Link to={paths.index}>Home</Link>
                        </li>
                        <li className={styles.link}>
                            <Link to={paths.about}>About</Link>
                        </li>
                        <li className={styles.link}>
                            <Link to={paths.contact}>Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.navLinks2}>
                    <ul className={styles.list}>
                        <li className={styles.link}>
                            <Link to={paths.markets}>Markets</Link>
                        </li>
                        <li className={styles.link}>
                            <Link to={paths.communications}>Communications</Link>
                        </li>
                        <li className={styles.link}>
                            <a target='_blank' href='https://www.ubitheraplay.com/' rel='noopener noreferrer'>
                                UbiTheraPlay
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Address */}
                <div className={styles.addressDiv}>
                    <h2 className={styles.addressTitle}>Address</h2>
                </div>
                <div className={styles.addressContainer}>
                    <address className={styles.address}>Penrhyn Road</address>
                    <address className={styles.address}>Kingston upon Thames</address>
                    <address className={styles.address}>Surrey, KT1 2EE</address>
                </div>

                {/* Footer Copyright */}
                <div className={styles.copyright}>&copy; 2021 Ubitech Ltd</div>

            </div>
        </footer>
    )
}

export default Footer
