import React, { useEffect } from 'react';
import { 
    BrowserRouter as Router,
    Routes,
    Route, 
    useLocation
} from 'react-router-dom';

import Layout from './components/Layout';
import Home from './pages/Home';

import path from './constants/paths';
import About from './pages/About';
import Comms from './pages/Comms';
import Markets from './pages/Markets';
import Contact from './pages/Contact';

function App() {
    

    return (
        <>
            <Router>
                <Layout>
                    <Routes>
                        <Route path={path.about} element={<About />}/>
                        <Route path={path.communications} element={<Comms />}/>
                        <Route path={path.markets} element={<Markets />}/>
                        <Route path={path.contact} element={<Contact />}/>
                        <Route path='*' element={<Home />}/>
                    </Routes>
                </Layout>   
            </Router>
        </>
    );
}

export default App;
